@import 'assets/scss/variables.scss';

.rightMenu {
  border-right: 1px solid $border-color;
  .logoutLink {
    color: $light-gray;
    font-size: $xs;
    padding: 9px;
    border-radius: 0;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: 1px solid $black;
    }
  }
}
