@import 'assets/scss/variables.scss';

.searchAutoList {
  position: absolute;
  width: 100%;
  top: 37px;
  background: $white;
  border: solid 1px $input-border;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 999;
  > ul {
    > li {
      a {
        color: $black;
        display: block;
        padding: 10px;
        border-bottom: solid 1px $input-border;
        cursor: pointer;
      }
      &.selected {
        background-color: $link-color;
        a {
          color: $white;
        }
      }
    }
  }
}
