@import 'assets/scss/mixins/breakpoints.scss';
.cmnRow {
  display: flex;
  color: $dark-gray;
  position: relative;

  .toggleButton {
    width: 14px;
    height: 20px;
    margin-right: 22px;
  }
  > div {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 15px;
    word-break: keep-all;
    @include md {
      padding: 15px 9px;
    }
    @include xs {
      padding: 13px;
    }
    &:first-child {
      padding-left: 20px;
      flex-direction: row;
      @include xs {
        padding-left: 15px;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $input-border;
  }
  &.tableSm {
    margin-bottom: 7px;
    border-bottom: 0;

    > div {
      padding: 5px 0 3px;
    }
  }
  &.ellipsis {
    > div {
      -webkit-box-orient: vertical;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 5px;
      @include lg {
        margin-bottom: 0;
        padding: 6px 0 0;
      }
    }
    @include lg {
      margin-bottom: 0;
    }
  }
}
