@import 'assets/scss/container.scss';
@import 'assets/scss/mixins/utils.scss';
@import 'assets/scss/mixins/breakpoints.scss';

.main {
  min-height: 500px;
  text-align: center;
  @include flex-center();
  flex-direction: column;
}
.mainStyle {
  flex: 1 0 auto;
}

.footer {
  height: 50px;
}

.midSection {
  display: flex;
}

.header-bar-container {
  min-width: 774px;
  width: 100%;
  * {
    box-sizing: border-box;
  }
}

.flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.navBarWrapper {
  background: $white;
  border-bottom: 1px solid $border-color;

  @include sm {
    border-bottom: 0;
  }
}

.spacer {
  flex: 1;
}
