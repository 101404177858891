@import 'assets/scss/mixins/breakpoints.scss';
.shipToItemContainer {
  display: flex;
  align-items: flex-start;
  background-color: $white;
  & > *:hover {
    background-color: $hover-bg;
  }
}
.starStyling {
  margin-right: 8px;
}
