@import 'assets/scss/mixins/breakpoints.scss';

.footerItem {
  margin-bottom: 15px;

  button {
    color: $white;
    font-size: 16px;
    padding: 0;
    pointer-events: none;
    border: none;
    background-color: transparent;
    font-weight: $font-weight-bold;
    text-align: left;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $white;
    }

    @include sm {
      pointer-events: initial;
      font-size: 14px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list {
    li {
      padding: 3px 0;

      a {
        color: $white;
        &:focus {
          box-shadow: $white 0px 0px 0px 2px;
          outline: 0;
        }
      }
    }
  }
}

.active {
  transform: rotate(180deg);
}

.icon {
  display: block;
  transition: all 0.3s ease-in-out;
  font-size: $extra-small-font;

  @include min-sm {
    display: none;
  }
}
