@import 'assets/scss/mixins/breakpoints.scss';
.footerConnect {
  background-color: $light-gray;
  padding: 20px 0;
  p {
    color: $white;
    margin-bottom: 8px;
    font-weight: $font-weight-bold;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include xs {
      flex-direction: column;
      align-items: flex-start;
    }
    .connectBlock {
      .list {
        display: flex;
        align-items: center;
        &.listBlock {
          @include xs {
            flex-direction: column;
            align-items: flex-start;
            li:first-child {
              margin: 40px 0 10px 0;
            }
          }
        }
        > li {
          padding-right: 15px;
          a {
            display: block;
            color: transparent;
            &:focus {
              box-shadow: 0px 0px 0px 1px $black;
              outline: 0;
            }
            > span {
              font-size: 30px;
              color: $white;
              margin-left: -5px;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
