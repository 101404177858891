@import 'assets/scss/mixins/breakpoints.scss';
.findAccountTableWrapper {
  padding: 0 15px 0 15px;
  .tableWrapper {
    margin-bottom: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    > div {
      min-width: 840px;
      > div:first-child {
        padding-right: 15px;
        @include md {
          padding-right: 0;
        }
      }
      @include md {
        margin-bottom: 8px;
      }
    }

    .noContent {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 300px;
      text-align: center;
    }

    .findAccount {
      + div {
        > div {
          > div {
            > div {
              border-bottom: 1px solid $border-color;
            }
          }
        }
        max-height: 270px;
        border: 1px solid $border-color;
      }

      > *:nth-of-type(1) {
        flex-basis: 12%;
        justify-content: center;
      }
      > *:nth-of-type(2) {
        flex-basis: 11%;
      }
      > *:nth-of-type(3) {
        flex-basis: 16%;
      }
      > *:nth-of-type(4) {
        flex-basis: 13%;
      }
      > *:nth-of-type(5) {
        flex-basis: 22%;
      }
      > *:nth-of-type(6) {
        flex-basis: 14%;
      }
      > *:nth-of-type(7) {
        flex-basis: 12%;
      }
    }
  }
}
.tableButton {
  display: flex;
  justify-content: center;
}
.selectAccountBtn {
  display: block;
}

.starIcon {
  font-size: 15px;
}
