.tabListWrapper {
  display: flex;
  flex-wrap: wrap;
  > *:first-child {
    border-top-left-radius: 4px;
  }

  > *:last-child {
    border-top-right-radius: 4px;
  }
}
