@import 'assets/scss/mixins/breakpoints.scss';

.customRow {
  margin-right: 0;
  margin-left: 0;
}

.sharedNoteIcon {
  margin-right: 8px !important;
}

.saveButtonSection {
  @include xs {
    margin-top: 15px !important;
  }
}

.selectBuyer {
  @include min-sm {
    position: relative;
    .buyerMsg {
      position: absolute;
      top: 8px;
      white-space: nowrap;
    }
  }
}

.customRow {
  > * {
    flex-basis: auto;
  }
}
