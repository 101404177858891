@import 'assets/scss/mixins/breakpoints.scss';
.cartButton {
  display: flex;
  padding: 20px 15px;
  justify-content: space-between;
  .checkOutButton {
    a {
      padding: 9px 25px;
      line-height: 18px;
      font-size: $body-font;
      border-radius: $btn-border-radius;
      text-decoration: none;
      align-items: center;
      color: $white;
      border: 1px solid $btn-border;
      background: $link-color;
      transition: all 0.3s ease;
      font-family: $font-family-medium;
      display: block;
      &:hover {
        background: transparent;
        color: $link-color;
      }
    }
  }
  .viewButton {
    a {
      padding: 9px 25px;
      line-height: 18px;
      font-size: $body-font;
      border-radius: $btn-border-radius;
      text-decoration: none;
      align-items: center;
      border: 1px solid $btn-border;
      transition: all 0.3s ease;
      font-family: $font-family-medium;
      display: block;
      &:hover {
        background: $link-color;
        transition: all 0.3s ease;
        color: $white;
      }
    }
  }
}
.catlogueLink {
  padding: 10px 15px;
  font-size: $medium-font;
  a {
    padding-right: 3px;
  }
}
