@import 'assets/scss/mixins/breakpoints.scss';
.manageAddressWrapper {
  display: grid;

  padding: 15px 10px;
  .addAddressButtonSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0px 5px;
    @include min-xs {
      flex-wrap: wrap;
    }
  }
  .manageAddressDetailsMsg {
    text-align: center;
    padding: 20px 0;
    @include min-md {
      padding: 10px 25px;
    }
  }
}

.modalHeading {
  @include min-xs {
    font-size: $lg;
  }
  @include min-sm {
    font-size: $xxLg;
  }
  font-size: $xs;
  font-weight: bold;
}
.cancelBtn {
  margin-right: 15px;
}
.modalButton {
  max-height: inherit;
}
.search {
  position: relative;
  width: 250px;
  top: 5px;
}
.sortingSection {
  @include min-xs {
    margin-left: auto;
  }
}
.addressListScrollbar {
  max-height: 458px;
  margin-bottom: 5px;
}
.AddressList {
  margin-left: 0;
  margin-right: 0;
}
