@import 'assets/scss/variables.scss';

.mainBlock {
  padding: 10px 15px 20px;
  input {
    border: 1px solid $input-border;
  }
  button {
    width: 100%;
    margin-top: 5px;
    font-family: $font-family-medium;
  }
  .padding {
    padding: 15px 0;
  }
  .mt {
    margin-top: 2px;
  }
}
