.menuBanner {
  background: #ececec;
  height: auto;
  width: 100%;
  overflow: hidden;
  display: block;
  margin-top: 10px;
  > * {
    margin-bottom: 0;
  }
}
