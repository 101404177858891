@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';
.addressList {
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  @include min-xs {
    flex-direction: row;
    flex-wrap: wrap;
  }
  // for all children
  & > * {
    @include flex-width(100%);
    @include min-xs {
      @include flex-width(50%);
    }
    @include min-md {
      @include flex-width(33.33%);
    }
  }
}

.modalHeading {
  font-size: $xxLg;
  font-weight: bold;
}
