@import 'assets/scss/variables.scss';
.hamburgerMenuCategoryListToggle {
  padding: 0;
  border: 0;
  background: $white;
  display: flex;
  color: $dark-gray;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: row-reverse;
  font-size: $xlg;
  line-height: 24px;
  font-family: $font-family-regular;

  // The icon
  span {
    font-size: 6px;
    margin-left: 10px;
    padding: 0;
    transition: all 0.3s;
  }
}
