@import 'assets/scss/mixins/breakpoints.scss';

.menuListItem {
  background-color: $hover-bg;
  border-bottom: 1px solid $panel-border;
  list-style-type: none;
}

.link {
  color: $dark-gray;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: bold;
  background: $hover-bg;
  border-bottom: 1px solid $panel-border;
  display: block;

  &:hover {
    color: $link-color;
    background-color: $white;
    @include xs {
      color: $dark-gray;
      background-color: $hover-bg;
    }
  }
}

.linkActive {
  color: $link-color;
  background-color: $white;
}
