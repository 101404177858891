@import 'assets/scss/mixins/breakpoints.scss';

.columnXs {
  @include sm {
    flex-direction: column;
  }
}

.flexItem {
  flex: 1 1 0;
  padding: 0 3px;
  @include sm {
    flex: none;
  }
}

.footer {
  font-family: $font-family-regular;
  font-size: $md;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  margin-top: 30px;
  ol,
  ul,
  dl {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    color: $link-color;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
}
.footerWrapper {
  padding: 50px 0 10px;
  background-color: $dark-gray;
  @include sm {
    padding: 30px 0 15px;
  }
}
