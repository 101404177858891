@import 'assets/scss/mixins/breakpoints.scss';
.footerCopyright {
  background-color: $dark-gray;
  text-align: center;
  color: $white;
  padding: 34px 0;
  p {
    margin-bottom: 12px;
    @include xs {
      margin-bottom: 5px;
    }
  }
  .list {
    display: flex;
    justify-content: center;
    @include xs {
      flex-wrap: wrap;
    }
    li {
      padding: 0 10px;
      position: relative;
      + li {
        &:before {
          position: absolute;
          content: '';
          border-left: 1px solid $white;
          left: 0;
          transform: translate(-50%, -50%);
          top: 50%;
          width: 1px;
          height: 70%;
        }
      }
      a {
        color: $white;
        &:focus {
          box-shadow: $white 0px 0px 0px 1px;
          outline: 0;
        }
      }
    }
  }
}
