@import 'assets/scss/mixins/breakpoints.scss';
.costCenterTableWrapper {
  overflow: auto;
  .infoLabel {
    white-space: nowrap;
    padding-top: 5px;
    padding-right: 5px;
    font-size: 18px;
    height: 27px;
    align-self: center;
  }
  .tableDataScroll {
    max-height: 400px;
  }
  .listWrapper {
    border: 1px solid $border-color;
    border-radius: 4px;
    > *:first-child {
      white-space: nowrap;
      line-height: normal;
    }
    .listTable {
      > *:nth-of-type(1) {
        flex-basis: 5%;
        margin: 0;
      }
      > *:nth-of-type(2) {
        flex-basis: 15%;
      }
      > *:nth-of-type(3) {
        flex-basis: 13%;
      }
      > *:nth-of-type(4) {
        flex-basis: 23%;
      }
      > *:nth-of-type(5) {
        flex-basis: 10%;
      }
      > *:nth-of-type(6) {
        flex-basis: 15%;
      }
      > *:nth-of-type(7) {
        flex-basis: 2%;
      }
    }
    .listTableWithoutSelectAddressOption {
      > *:nth-of-type(1) {
        flex-basis: 0%;
        margin: 0;
        padding: 0;
      }

      > *:nth-of-type(2) {
        flex-basis: 18%;
        padding-left: 0px;
      }

      > *:nth-of-type(3) {
        flex-basis: 15%;
      }

      > *:nth-of-type(4) {
        flex-basis: 30%;
      }

      > *:nth-of-type(5) {
        flex-basis: 10%;
      }

      > *:nth-of-type(6) {
        flex-basis: 10%;
      }

      > *:nth-of-type(7) {
        flex-basis: 2%;
      }
    }
    @include lg {
      width: 1200px;
    }
  }
}
.iconStatus {
  margin-right: 5px;
}
