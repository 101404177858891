@import 'assets/scss/mixins/breakpoints.scss';

// TODO: to be refactor, every html element should become reusable
.navbarNav {
  margin: 0 auto;
  width: 100%;

  @include md {
    max-width: 820px;
  }

  @include sm {
    display: none;
  }

  // Targets "become a customer"
  > li > a {
    padding: 13px 20px;
    display: flex;
    color: $dark-gray;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: $font-family-medium;

    &:focus {
      box-shadow: $dark-gray 0px 0px 0px 1px inset;
      outline: 0;
    }

    // / Targets "Become a Customer" (the text)
    > span {
      padding: 0 6px;
    }
  }
}
