.SignInTopBarFlex {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 15px;
  color: #444444;
  button {
    background-color: transparent;
    padding: 0;
    border: 0;
  }
  p {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
  }
  > div {
    margin-top: 8px;
  }
}
