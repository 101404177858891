@import 'assets/scss/mixins/supportMenuList.scss';
@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.cartList {
  @include support-menu-list;
  .cartPanel {
    border-top: 4px solid $link-color;
    color: $dark-gray;
  }
  .cartWrapper {
    background: $white;
    .cartBody {
      padding: 0 15px;
      .emptyCard {
        padding-top: 15px;
      }
      .totalList {
        display: flex;
        justify-content: flex-end;
        font-size: $medium-font;
        .totalAmount {
          text-align: right;
          margin-left: auto;
        }
        @include ie() {
          justify-content: flex-start;
        }
        > span {
          width: 50%;
        }
      }
    }
  }
  .infoWrapper {
    padding: 30px 50px;
  }
  @include ie() {
    max-width: 320px;
  }
}

.menuCount {
  position: absolute;
  bottom: 14px;
  left: 20px;
  font-size: 12px;
  color: $white;
  @include xs {
    bottom: 17px;
    left: 11px;
  }
}
