@import 'assets/scss/mixins/breakpoints.scss';

.veryDarkGrey {
  color: $very-dark-gray;
}
.white {
  color: $white;
}

.menuIcons {
  font-size: $fs-large;
  color: $dark-gray;
  position: relative;
  padding-bottom: 1px;
  line-height: 1;
}
.menuText {
  font-size: $xs;
  line-height: 11px;
  @include sm {
    display: none;
  }
}
