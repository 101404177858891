@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/breakpoints.scss';

@mixin flexStyle($justifyContent) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: $justifyContent;
}
.error {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  display: flex;
  height: 100%;
  .errorContent {
    margin: 0 auto;
    min-height: 400px;
    @include flexStyle(center);
    flex-direction: column;
    .errorBtn {
      @include flexStyle(space-between);
      margin-top: 30px;
      width: 100%;
      button {
        @include sm {
          margin-bottom: 10px;
        }
      }
    }
  }
}
