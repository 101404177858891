@import 'assets/scss/mixins/breakpoints.scss';

.menuContent {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 61px;
  height: 100%;
  cursor: pointer;
  text-align: center;

  @include sm {
    min-width: 40px;
  }
  @include xs {
    min-width: 36px;
  }

  &:focus {
    outline: 1px solid $dark-gray;
  }
}

.headerButtons {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $font-family-regular;
  width: 100%;
  span {
    padding: 0;
  }
  &:focus {
    border-radius: 0;
  }
}

.veryDarkGrey {
  color: $very-dark-gray;
}
.white {
  color: $white;
}
