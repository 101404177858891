@import 'assets/scss/variables.scss';

// When animation is true
.animateHidden {
  position: absolute;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  margin: 20px auto 0;
}

.animateVisible {
  opacity: 1;
  visibility: visible;
  margin: 1px auto;
  z-index: 2;
}

.defaultHidden {
  display: none;
}

.defaultVisible {
  display: block;
}
