@import 'assets/scss/mixins/breakpoints.scss';

.filterWrapper {
  padding: 20px 15px;
  box-shadow: $filter-box-shadow;
  .filterTop {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    .search {
      border: 1px solid $border-color;
      position: relative;
      flex-grow: 1;
      margin-right: 12px;
      span {
        background: none;
        font-size: $medium-font;
        color: $dark-gray;
        padding: 5px 8px;
      }
      @include xs {
        margin-bottom: 10px;
        margin-right: 0;
        input {
          border: 0;
        }
      }
    }
    @include xs {
      flex-direction: column;
      align-items: initial;
    }
  }
  .filterBottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .filterBottomLeft {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .filterSelect {
        display: flex;
        margin-right: 12px;
        select {
          width: 208px;
          height: 36px;
          border: 1px solid $border-color;
          background: $white;
          @include xs {
            flex-grow: 1;
            width: 100%;
          }
          &:focus {
            outline: 0;
            box-shadow: 0px 0px 0px 1px $dark-gray;
          }
        }
        @include xs {
          margin-right: 0;
          width: 100%;
          margin-bottom: 10px;
        }
      }
      @include xs {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
      }
    }
    .btnFilterWrapper {
      margin-right: auto;
      @include xs {
        order: 1;
        width: 100%;
      }
    }
    .contextMenuWrapper {
      margin: 0 30px 0 20px;
      @include max-md {
        margin: 0px 15px;
      }
      @include xs {
        margin: 15px 0 0;
        order: 2;
        margin-left: auto;
      }
    }
  }
  @include xs {
    padding: 20px 12px;
  }
}
.tableFiltering {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px 17px 8px 15px;
  [data-input='customInput'] {
    > div {
      margin-bottom: 0;
      line-height: normal;
      align-self: center;
      display: flex;
    }
  }
  @include xs {
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 8px;
    }
    > span {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
