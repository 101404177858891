@import 'assets/scss/mixins/breakpoints.scss';
.hamburgerMainSubCategoryWrapper {
  > * {
    padding: 8px 0;
  }
  li {
    &.active {
      > button {
        span {
          transform: rotate(-180deg);
        }
      }
      .navBarSubCategory {
        display: block;
      }
    }
  }
}

.navBarSubCategory {
  display: none;
  > li {
    padding: 4px 0 4px 25px;
    a {
      color: $very-dark-gray;
    }
  }
}

.showMoreBtn {
  line-height: 24px;
  border: 0;
  text-decoration: underline;
  font-size: $body-font;
  color: $very-dark-gray;
  font-family: $font-family-regular;
  text-transform: lowercase;
  padding: 4px 0 4px 25px;
}

.displayBlock {
  display: block;
}
