@import 'assets/scss/mixins/breakpoints.scss';

.hamburgerNavBarChildCategoryList {
  padding: 8px 0;

  ul {
    > li {
      padding: 4px 0 4px 40px;
      a {
        color: $very-dark-gray;
      }
    }
  }
  .hamburgerNavBarChildCategoryListHeader {
    display: flex;
    align-items: center;
    > button {
      span {
        font-size: $fsXl;
        color: $dark-gray;
        margin-left: 0px;
      }
    }

    > a {
      line-height: 24px;
      text-transform: uppercase;
      padding-left: 12px;
    }
  }
}

.previousIcon {
  border: 0;
  background: #fff;
  padding: 0;
  display: flex;
  color: #444444;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-direction: row-reverse;
  font-size: 15px;
  line-height: 24px;
  font-family: 'MotivasanRegular';

  // The icon
  span {
    font-size: 6px;
    margin-left: 10px;
    padding: 0;
    transition: all 0.3s;
  }
}
