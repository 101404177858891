@import 'assets/scss/mixins/breakpoints.scss';
.tabWrapper {
  padding-bottom: 20px;
  [data-rating='selected'] {
    label {
      > span {
        font-size: $fs-large;
      }
    }
  }

  .tableButton {
    display: flex;
    justify-content: center;
    button {
      min-width: 150px;
    }
  }
}
