@import 'assets/scss/mixins/breakpoints.scss';
.headerMiddle {
  @include min-md {
    height: 60px;
  }

  height: auto;
  border-bottom: 1px solid $border-color;

  .headerMiddleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include max-md {
      flex-flow: row wrap;
      background: $white;
    }
    @include md {
      > div:first-child {
        margin-left: 0;
        order: 0;
        padding-left: 10px;
        max-width: 160px;
      }
    }
  }
}
