@import 'assets/scss/mixins/breakpoints.scss';

@mixin support-menu-list {
  position: absolute;
  top: 100%;
  background: $hover-bg;
  min-width: 320px;
  right: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  margin: 20px 0 0 0;
  z-index: 9;
  box-shadow: $support-box-shadow;
  @include xs {
    top: 42px;
    width: 100%;
    min-width: 300px;
  }
  // Affects all list account menu
  ul {
    > li {
      > a {
        padding: 6px;
        display: block;
        &:hover {
          background-color: $white;
          color: $link-color;
          text-decoration: none;
        }
      }
    }
  }
}
