@import 'assets/scss/colors.module.scss';
@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/breakpoints.scss';

.tabsAndFilterSection {
  @include min-sm {
    width: 72%;
  }
  @include min-md {
    width: 57%;
  }
  @include min-lg {
    width: 600px;
  }
  // REFACTOR: To refactor, similar code found in ManageAddressesContainer.module.scss
  .manageAddressTab {
    max-width: 40%;
    background: none;
    padding-bottom: 10px;
    color: $dark-gray;

    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    flex-basis: unset;
    border-radius: 0;
    padding: 0px 0px 0px;
    font-weight: $font-weight-bold;
    font-size: $sm;
    line-height: 36px;
    margin: 0px 10px;

    @include min-xs {
      margin: 0px;
      font-size: $xlg;
    }

    @include min-sm {
      margin: 0px;
      font-size: $lg;
    }
  }
}

.tableCell {
  word-break: break-word;
}
.search {
  border: 1px solid $border-color;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 250px;
}
.assignTableWrapper {
  width: 950px;
  border: 1px solid $input-border;
  border-radius: 5px;
  .assignTable {
    > *:nth-of-type(1) {
      flex-basis: 10%;
    }
    > *:nth-of-type(2) {
      flex-basis: 20%;
    }
    > *:nth-of-type(3) {
      flex-basis: 20%;
    }
    > *:nth-of-type(4) {
      flex-basis: 30%;
    }
    > *:nth-of-type(5) {
      flex-basis: 20%;
    }
  }
}
.tableDataScroll {
  max-height: 400px;
}
.assignAddressWrapper {
  overflow: auto;
}
.listGridView {
  display: flex;

  // All buttons
  > * {
    border-radius: 0;
    border: 1px solid $dark-gray;
    padding: 9px 18px;
    span {
      font-size: $lg;
      color: $dark-gray;
      padding-right: 0;
    }

    @include xs {
      padding: 9px 13px;
    }

    &:focus {
      box-shadow: 0px 0px 0px 1px $dark-gray;
      z-index: 1;
    }

    &.active {
      background-color: $dark-gray;
      span {
        color: $white;
      }
    }
    &:nth-of-type(3) {
      > * {
        font-size: $xxLg;
      }
    }
  }

  // Left button
  > *:first-child {
    border: 1px solid $dark-gray;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  // Right button
  > *:last-child {
    border: 1px solid $dark-gray;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.customSearchBox {
  position: relative;
  width: 250px;
  margin-right: 15px;
  > * {
    margin: 0;
  }
}
.sortingSection {
  @include min-xs {
    margin-left: auto;
  }
}
