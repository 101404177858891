@import 'assets/scss/variables.scss';

.itemList {
  padding: 12px 0;
  color: $very-dark-gray;
  ul {
    li {
      display: flex;
      border-bottom: 1px solid $input-border;
      font-size: $medium-font;
      justify-content: space-between;
      padding: 9px 0;
      > p {
        font-size: $medium-font;
        margin: 0;
        padding: 0;
      }
    }
  }
}
