@import 'assets/scss/variables.scss';
.cartCount {
  display: flex;
  justify-content: space-between;
  background: $link-color;
  width: 100%;
  padding: 8px 10px;
  line-height: 19px;
  .cardCountLeft {
    span {
      width: 60px;
      display: block;
    }
  }
  .cardCountRight {
    display: flex;
  }
}
.alignRight {
  margin-left: auto;
}
