@import 'assets/scss/mixins/breakpoints.scss';

.accountContentHeader {
  padding: 8px 8px 12px;
  border-bottom: 1px solid $border-color;
  min-height: 52px;
  background-color: $hover-bg;

  h5 {
    text-overflow: ellipsis;
    width: 85%;
    overflow: hidden;
  }
  .shipNumberText {
    margin-right: 3px;
  }
}

.accountContent {
  flex-grow: 1;
  word-wrap: break-word;

  @include min-xs {
    width: 320px;
  }

  .selectBox {
    padding: 20px 15px;
    position: relative;

    @include min-xs {
      padding: 10px 15px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      align-items: center;

      .leftTitle {
        font-size: $body-font;
        color: $medium-gray;
        font-weight: 600;
      }

      .rightTitle {
        a {
          font-size: $body-font;
          color: $dark-gray;
        }
      }
    }

    .inputWrapper {
      div {
        display: block;
      }

      select {
        border: 1px solid $select-border;
        width: 100%;
        min-height: 36px;
        padding: 5px 30px 5px 10px;
        background: transparent;

        &:focus {
          outline: 0;
        }
      }
    }

    &.active {
      @include min-xs {
        display: none;
      }
    }
  }
}
