@import 'assets/scss/mixins/breakpoints.scss';
.postalCodeWrapper {
  display: grid;

  padding: 25px 10px;
  .postalCodeDetailsMsg {
    text-align: center;
    padding: 20px 0;
    @include min-md {
      padding: 20px 35px;
    }
  }

  .formField {
    width: 100%;
    @include min-md {
      width: 50%;
    }
  }
}

.textMargin {
  margin-bottom: 20px;
  margin-top: 20px;
}
.cancelBtn {
  margin-right: 15px;
}
.welcomeModalWrapper {
  padding: 0 15px;
  @include min-md {
    padding: 0 40px;
  }
}
