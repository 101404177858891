@import 'assets/scss/mixins/breakpoints.scss';

.supportMenu {
  display: inline-flex;

  @include max-md {
    flex: 1 0 0;
    order: 2;
    justify-content: flex-end;
  }

  > li {
    &:focus {
      outline: 1px solid $dark-gray;
    }
    flex-direction: column;
    display: flex;
    min-height: 60px;
    padding: 0 5px;
    border-left: 1px solid $border-color;
    align-items: center;
    justify-content: center;
    > div {
      height: 100%;
    }
    @include sm {
      min-height: 40px;
      border-left: 0;
    }

    @include min-xs {
      position: relative;
    }

    &:last-child {
      position: relative;
      a {
        background: $link-color;
        color: $white;

        z-index: 9;
        @include xs {
          position: inherit;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 3px #ff00004f,
            inset 0 0 0 1.25px rgba(127, 82, 82, 0.5),
            0 13px 27px -5px rgba(93, 50, 50, 0.1),
            0 8px 16px -8px rgba(0, 0, 0, 0.3),
            0 -6px 16px -6px rgba(0, 0, 0, 0.01);
        }
      }
      > div {
        z-index: 9;
        > span,
        > div {
          color: $white;
        }
      }
      &:after {
        background: $link-color;
        color: $white;
        min-height: 65px;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        border-radius: 0 0 3px 3px;

        @include sm {
          min-height: 45px;
        }
        @include xs {
          left: inherit;
          right: 0;
          width: 50px;
          min-height: 48px;
        }
      }
    }
  }
}
