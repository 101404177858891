@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';
@import 'assets/scss/colors.module.scss';

.tabStyle {
  flex-basis: 0;
  flex-grow: 1;
  @include flex-center();
  border-radius: 0;
  padding: 12px 20px;
  width: 100%;
  background-color: $hover-bg;
  font-weight: normal;
  font-size: $fs2xl;
  letter-spacing: 0;
  line-height: 36px;
  color: $dark-gray;
  font-family: $font-family-regular;

  @include xs {
    padding: 10px;
    font-size: $fs-large;
    line-height: normal;
  }
  &:focus {
    box-shadow: none;
    border: 1px dashed $link-color;
  }
}

.active {
  background-color: $white;
  font-family: $font-family-medium;
}

.link {
  padding: 0px 20px;
  line-height: 36px;
  @include flex-center();
  background-color: $white;
  color: $light-gray;
  &.active {
    background-color: transparent;
    border-bottom: 3px solid $link-color;
    color: $dark-gray;
  }
}
