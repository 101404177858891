@import 'assets/scss/variables.scss';
.findAccountLink {
  font-size: $small-font;
  font-family: $font-family-medium;
  line-height: 13px;
  color: $light-gray;
  margin-left: 2px;
  text-decoration: underline;
  border-radius: 0;
}

.findAccountLink:hover {
  background-color: $white;
  color: $link-color;
  text-decoration: none;
}

.disabled {
  color: $light-gray;
  white-space: nowrap;
}
