@import 'assets/scss/mixins/supportMenuList.scss';
@import 'assets/scss/mixins/utils.scss';

.signInList {
  @include support-menu-list;
  .signInPanel {
    background-color: $hover-bg;
    position: relative;
    z-index: 999;
  }
  @include ie() {
    max-width: 320px;
  }
}
