@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/breakpoints.scss';

.cmnRow {
  display: flex;
  color: $dark-gray;
  position: relative;
  > div {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 15px;
    word-break: keep-all;
    @include md {
      padding: 15px 9px;
    }
    @include xs {
      padding: 13px;
    }
    &:first-child {
      padding-left: 20px;
      flex-direction: row;
      @include xs {
        padding-left: 15px;
      }
    }
  }
  &.cmnHeader {
    background-color: $hover-bg;
    border-radius: 4px 4px 0 0;
  }
  &.tableSm {
    margin-bottom: 7px;
    > div {
      padding: 5px 0 3px;
    }
  }
}

.bodyContainer {
  .cmnRow {
    border-bottom: 1px solid $cmn-row-border;
  }
}
