@import 'assets/scss/variables.scss';

.NavBarChildCategoryList {
  flex-wrap: wrap;
  column-count: 3;

  > li {
    width: 190px;
    list-style: none;
    page-break-inside: avoid;
    break-inside: avoid-column;
    &:focus {
      outline: 0;
    }
    > a {
      color: $dark-gray;
      font-weight: bold;
      padding: 4px 0;
      display: block;
      &:hover {
        color: $link-color;
      }
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 1px $dark-gray inset;
      }
    }

    > ul {
      margin-bottom: 20px;

      > li {
        a {
          color: $very-dark-gray;
          font-size: 12px;
          &:hover {
            color: $link-color;
          }
          &:focus {
            outline: 0;
            box-shadow: 0 0 0 1px $dark-gray inset;
          }
        }
        &:focus {
          outline: 0;
        }
      }
    }
  }
}
