@import 'assets/scss/mixins/breakpoints.scss';

.inputWrapper {
  &.searchWrapper {
    flex-grow: 1;

    input {
      border: 0;
    }
    button {
      padding: 0 2px 0 10px;
      @include xs {
        padding: 0;
      }
      &:focus {
        border-radius: 0;
        box-shadow: 0 0 0 1px $dark-gray;
      }
    }
  }
}

.inputFlex {
  transition: all 0.2s ease-in-out;
  display: flex;
}
.textInputWrapper {
  transition: all 0.2s ease-in-out;
}
.iconButton {
  > * {
    display: inline-flex;
    margin-left: -8px;
    cursor: pointer;
    align-items: center;

    background: none;
    font-size: $medium-font;
    color: $dark-gray;
    padding: 5px 8px;

    @include sm {
      padding: 9px 15px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    @include xs {
      margin-left: 0;
    }
  }
  &:disabled {
    pointer-events: none;
  }
  &:focus {
    > * {
      outline: 0;
      box-shadow: $white 0px 0px 0px 1px inset;
    }
  }
}
