@import 'assets/scss/variables.scss';

.content {
  padding: 20px 0;
}
.signInMessage {
  .loginNotification {
    font-size: $xs;
    color: $light-gray;
  }
  display: flex;
  align-items: center;
  .icon {
    color: $success-color;
    padding-right: 10px;
    font-size: $fsXl;
  }
  .iconDanger {
    color: $link-color;
    font-size: $small-font;
    padding-top: 2px;
    padding-right: 5px;
  }
}
