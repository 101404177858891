@import 'assets/scss/variables.scss';

.button {
  color: $dark-gray;
  border: 0;
  padding: 13px 20px;
  text-transform: uppercase;
  flex-direction: row-reverse;
  position: relative;
  z-index: 1;
  border-radius: 0;

  // The caret
  span {
    transition: all 0.2s ease-in-out;
    font-size: 6px;
    margin-left: 10px;
    padding: 0;
    position: relative;
    z-index: -1;
  }
}

.active {
  color: $link-color;
  background: $white;

  // Flip The caret
  span {
    color: $link-color;
    transform: rotate(180deg);
  }
}
