@import 'assets/scss/mixins/breakpoints.scss';

.mainDefaults {
  max-width: 920px;
  background: $white;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  min-height: 750px;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: flex;
  margin: 20px auto 0;
  @include md {
    max-width: 820px;
  }
}

.primaryDefault {
  height: auto;
  background: $hover-bg;
  width: 230px;
  max-width: 230px;
  a {
    &:focus {
      outline: 0;
    }
  }
  @include md {
    width: 165px;
    max-width: 165px;
  }
  // TODO: Do not remove until refactored.
  // Keeps the element color active on hover.
  a {
    &.active {
      color: $link-color;
      background-color: white;
      outline: 1px solid $dark-gray;
    }

    &.activeHover {
      color: $link-color;
      background-color: white;
    }
  }
}

.secondaryDefaults {
  width: 100%;
  padding: 30px 20px;
  flex: 1;
  // All items are hidden by default
  > * {
    display: none;
  }
}

// When item is hovered
.active {
  display: block;
}

// When mega menu is visible
.visible {
  opacity: 1;
  visibility: visible;
  margin: 1px auto;
  z-index: 20;
}

.centerAndSetSmall {
  min-height: 0;
  width: 230px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @include md {
    width: 165px;
    max-width: 165px;
  }
}
