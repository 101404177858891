@import 'assets/scss/mixins/utils.scss';
@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/container.scss';
.content {
  flex: 1 0 auto;

  @include ie() {
    display: flex;
    flex-direction: column;
  }
}
