@import 'assets/scss/mixins/supportMenuList.scss';
.accountList {
  @include support-menu-list;
  min-width: 390px;
  .account {
    display: flex;
    background-color: $white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  }
  [data-menuclose='close'] {
    right: 8px;
  }
  @include xs {
    min-width: 100%;
  }
}
