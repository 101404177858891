@import 'assets/scss/mixins/breakpoints.scss';
.accountMenu {
  display: flex;

  .subListWrapper {
    max-height: 240px;
    overflow-y: auto;
    flex-grow: 1;

    ul.subList {
      display: none;
    }
    ul.subList.active {
      display: block;
      > li {
        width: 100%;
        border: 0;
        a {
          color: $dark-gray;
          padding: 5px 8px;
          font-weight: 300;
          line-height: 1.5;
          margin: 2px 0 0 2px;
          width: 98%;
          &:hover {
            background-color: $white;
            color: $link-color;
            text-decoration: none;
          }
        }
      }
    }
    .accountDetail {
      .contentNote {
        padding-top: 10px;
        span {
          color: $very-dark-gray;
          line-height: 14px;
          display: block;
        }
      }
      .contentText {
        span {
          padding-right: 5px;
          @include xs {
            padding-right: 0;
          }
        }
      }
    }
  }

  @include xs {
    min-width: 100%;
    min-height: 140px;
  }
}

.linkActive {
  color: $link-color;
  background-color: $white;
}

.activeSecondaryMenu {
  outline: 1px solid black;
}
