@import 'assets/scss/mixins/breakpoints.scss';

.lockInputWrapper {
  flex-grow: 1;
  &.customSelectFiledWrapper {
    > * {
      > * {
        > * {
          > * {
            > * {
              &:after {
                right: 21px;
              }
            }
          }
        }
      }
    }
    .iconButton {
      top: 25px;
    }
  }
  > *:nth-of-type(1) {
    width: 100%;
  }
  .iconButtonWrapper {
    position: absolute;
    right: 0px;
    padding: 0 5px;
    background-color: transparent;
    > * {
      padding: 0;
    }
    @include xs {
      background-color: $white;
    }
    .iconButton {
      height: 33px;
      border-radius: 0;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 1px;
      right: -3px;
      > * {
        padding-right: 0;
        font-size: 18px;
      }
      &:focus {
        border-radius: 0;
      }
    }
  }

  select {
    padding-right: 50px;
  }
}
