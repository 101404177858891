@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.headerTop {
  border-bottom: 1px solid $border-color;
  .headerTopBlock {
    display: flex;
    .leftSection {
      display: flex;
      margin-right: auto;
      align-items: center;
      .rightMenu {
        border-right: 1px solid $border-color;
        a {
          padding: 8px;
          display: block;
          &:hover {
            text-decoration: none;
          }
        }
        @include xs {
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
      select {
        padding: 10px 20px 8px 0;
      }
    }

    .rightSection {
      display: flex;
      align-items: center;
      border-right: 1px solid #e7e7e7;

      .rightMenu {
        @include hide-when-empty();
        border-left: 1px solid $border-color;
        display: flex;
        align-items: center;
        height: 100%;
        align-content: stretch;
        a {
          padding: 0 8px;
          display: flex;
          &:hover {
            text-decoration: none;
          }
          &:focus {
            outline: 0;
            box-shadow: $dark-gray 0px 0px 0px 1px inset;
          }
        }
        @include xs {
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
      select {
        padding: 10px 20px 8px 10px;
      }
    }

    & > *:last-child {
      border-right: none;
    }

    @include xs {
      flex-wrap: wrap;
    }
  }
  select {
    border: 0;
    background: none;
    font-size: $small-font;
    color: $light-gray;
  }
}

.rightMenu {
  @include hide-when-empty();
  display: flex;
  align-items: center;
  height: 100%;
  align-content: stretch;

  @include xs {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.deliverToText {
  padding-left: 5px;
}

.locationIcon {
  padding-left: 5px;
}

.postalCodeLink {
  color: $light-gray;
  font-size: $xs;
  padding: 9px;
  border-radius: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 1px solid $black;
  }
}
