@import 'assets/scss/variables.scss';

.dialogHeader {
  background: $hover-bg;
  border-radius: 4px;
}
.search {
  border: 1px solid $border-color;
  //margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 250px;
}
