@import 'assets/scss/variables.scss';

.mainBlock {
  padding: 12px 15px;

  input {
    border: 1px solid $input-border;
  }
  .block {
    button {
      width: 100%;
      font-family: $font-family-medium;
      margin-top: 10px;
    }
  }
  .forgotContent {
    padding: 15px 0 0;
    .forgotContentWrapper {
      a,
      span,
      button {
        font-size: 11px;
        padding: 0 2px;
      }
      button {
        color: $link-color;
        text-decoration: underline;
        &:focus {
          text-decoration: none;
        }
      }
      a {
        text-decoration: underline;
      }
    }
  }
  .padding {
    padding: 6px 0;
  }
}
.borderBottom {
  border-bottom: 1px solid $panel-border;
}
