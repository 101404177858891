@import 'assets/scss/mixins/breakpoints.scss';

.headerMiddle {
  @include min-md {
    height: 60px;
  }

  height: auto;
  border-bottom: 1px solid $border-color;

  .headerMiddleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include max-md {
      flex-flow: row wrap;
      background: $white;
    }
  }

  .searchCenter {
    display: flex;
    width: 610px;
    justify-content: space-between;

    @include min-lg {
      width: 685px;
    }

    @include max-md {
      margin: auto;
    }

    @include sm {
      width: 100%;
    }

    &.searchWithButtons {
      width: 100%;
      padding-right: 5px;

      @include min-lg {
        width: 840px;
      }
    }
  }

  .dFlex {
    display: flex;

    @include md {
      padding: 0 15px;
    }

    @include max-md {
      display: flex;
      flex: 1 100%;
      order: 3;
      border-top: 1px solid $border-color;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @include sm {
      padding: 10px 38px;
    }

    @include xs {
      padding: 10px 20px;
    }

    &.searchWrappers {
      @include sm {
        padding: 10px 10px;
      }
      @include xs {
        padding: 10px 4px;
      }
    }
  }

  .logoWrapper {
    display: inline-flex;
  }
}

.inputFields {
  flex-grow: 1;
  border-radius: 3px;
  border: 1px solid $light-gray;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  position: relative;

  @include xs {
    border: 0;
    flex-direction: column;
  }
}

.hiddenMd {
  display: block;

  @include xs {
    display: none;
  }
}

.inkToner {
  margin-left: 20px;

  @include xs {
    margin-left: 5px;
  }

  @include sm {
    margin-left: 0;
  }
}

.inkAndTonerLink {
  transition: all 0.3s ease;
  white-space: nowrap;
  background: $white;
  border-radius: 3px;
  display: flex;
  flex-direction: row-reverse;
  padding: 8px 24px;
  cursor: pointer;
  border: 1px solid $dark-gray;
  color: $dark-gray;
  align-items: center;
  text-decoration: none;

  @include max-md {
    padding: 8px;
  }

  @include sm {
    padding: 8px 4px;
    margin-left: 10px;
    max-height: 36px;
  }

  // icon styling
  span {
    transition: all 0.3s ease;
    color: $link-color;
    font-size: $fs-large;
    padding-right: 5px;

    @include xs {
      display: none;
    }
  }

  &:focus,
  &:hover {
    background: $dark-gray;
    color: $white;
    span {
      color: $white;
    }
  }
}
