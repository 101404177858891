@import 'assets/scss/mixins/breakpoints.scss';

.liveChat {
  border-left: 1px solid $border-color;
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
  padding-left: 2px;

  @include xs {
    display: flex;
    height: 100%;
    align-items: center;
  }

  button {
    background: $white;
    border: 0;
    padding: 0 7px;

    cursor: pointer;

    .iconHeadphone {
      display: none;

      @include xs {
        display: block;
        font-size: 18px;
      }
    }

    .hiddenSm {
      display: flex;

      @include xs {
        display: none;
      }
    }
  }

  .liveChatText {
    color: $tool-tip;
  }

  &.checkoutLiveChat {
    min-height: 60px;
    border-right: 1px solid $border-color;

    button {
      .hiddenSm {
        display: block;
      }

      .iconHeadphone {
        color: $light-gray;
        display: block;
        padding-bottom: 2px;
      }
    }

    .liveChatText {
      color: $light-gray;
      line-height: normal;
    }
  }
}
