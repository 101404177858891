@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.rightMenu {
  border-right: 1px solid $border-color;
  @include xs {
    display: flex;
    height: 100%;
    align-items: center;
  }
  > div {
    width: 40px;
  }
}

@include visuallyHidden();
