@import 'assets/scss/variables.scss';

.filteringInput {
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  height: 20px;
  padding-left: 18px;
  font-size: $small-font;
  line-height: 13px;
  color: $light-gray;

  &::placeholder {
    color: $dark-gray;
    opacity: 1;
    text-transform: capitalize;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: $white;
  }
  &:focus {
    outline: none;
    box-shadow: $dark-gray 0px 0px 0px 1px inset;
  }
  &.fullWidth {
    width: 100%;
  }
}

.large {
  font-size: $xlg;
}

.inputFilterContainer {
  display: flex;
  position: relative;
  &.isFavorite {
    .filteringInput {
      padding-left: 16px;
    }
  }
}

.inputFilterStarContainer {
  position: absolute;
  width: 10px;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  svg {
    width: 8px;
  }
}
