@import 'assets/scss/mixins/breakpoints.scss';

.search {
  border: 1px solid $border-color;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 250px;
}
.tableWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
}
.listTableScroll {
  max-height: 270px;
}
.listTable {
  align-items: center;
  > *:nth-of-type(1) {
    flex-basis: 10%;
    margin: 0;
  }
  > *:nth-of-type(2) {
    flex-basis: 25%;
  }
  > *:nth-of-type(3) {
    flex-basis: 25%;
  }
  > *:nth-last-child(1) {
    flex-basis: 40%;
    max-width: 40%;
  }
  .tableCell {
    word-break: break-word;
  }
}
.tableSortingButton {
  font-size: $xxLg;
  text-align: left;
  svg {
    position: relative;
    left: 3px;
    pointer-events: none;
  }
  &:focus {
    border-radius: 0;
  }
}
