.accountSelection {
  padding-bottom: 20px;
  min-height: 539px;
  display: flex;
  flex-direction: column;
}

.someHeightToBeChanged {
  min-height: 539px;
}
