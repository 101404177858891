@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.addressBoxButtonWrapper {
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin: 0 5px;
  }
  .toggleEditDelBtn {
    font-size: 20px;
    list-style: none;
    cursor: pointer;
  }
  .editDeleteButton {
    border: 1px solid $border-color;
    position: absolute;
    left: 25px;
    top: 25px;
    padding: 5px 10px;
    background: #f2f2f2;
    border-radius: 5px;
    & > button {
      padding: 5px 0;
      font-size: 9px;
      cursor: pointer;
      display: block;
      @include min-sm {
        font-size: 13px;
      }
    }
    .assignButton {
      border-top: 1px solid $border-color;
      border-radius: 0;
    }
    .deleteButton {
      border-top: 1px solid $border-color;
      border-radius: 0;
      width: 100%;
      text-align: left;
    }
  }
  .editDeleteButton:hover {
    box-shadow: 0 0 11px rgb(33 33 33 / 20%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
}

.addressText {
  @include min-xs {
    @include ellipsis();
    width: 97%;
  }
  word-break: break-word;
}

.addressBoxTitle {
  @include min-xs {
    @include ellipsis();
  }
  font-size: $fsXl;
  word-break: break-word;
}
.addressTextStyle {
  word-break: break-word;
}

.addressBoxButton {
  align-items: center;
  flex-wrap: wrap;
  &:nth-child(2n) {
    margin-right: 0;
  }
  &:nth-child(3n) {
    margin-left: 10px;
  }
}

.infoLabel {
  white-space: nowrap;
  padding-top: 5px;
  padding-right: 5px;
  font-size: 18px;
  height: 27px;
  align-self: center;
}

.addressTooltipIcon {
  width: 200px;
  padding: 5px;
  border-radius: 10px;
  > h6 {
    margin: 0px;
  }
  > p {
    padding-top: 5px;
    margin-bottom: 0px;
  }
}

.addressBoxCard {
  @include xs {
    width: 100%;
  }
}
.setDefaultBillingAddressButton {
  text-align: left;
}
.orderDetailInfo {
  box-shadow: none;
}
