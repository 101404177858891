@import 'assets/scss/spacing-utils.scss';
@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.shipToSelection {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 539px;
  padding: 44px 80px 10px;
  @include max-md {
    padding: 20px;
  }
  .changeShipToLabel {
    @include xs {
      margin-top: 20px;
    }
  }
  height: 100%;
}

.fontMedium {
  font-family: $font-family-medium;
}

.xLargeFont {
  font-size: $fsXl;
}
.mb5 {
  margin-bottom: 5px;
}

.mb20 {
  margin-bottom: 20px;
  @include xs {
    > div {
      margin: 0;
    }
  }
}

.bottomSection {
  text-align: center;
}

.bottomButton {
  min-width: 150px;
  align-self: center;
}

.whyAmI {
  display: flex;
  align-items: center;
}

.heading {
  min-height: 65px;
  @include flex-center();
}
