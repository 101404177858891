@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.logo {
  min-height: 30px;
  width: 160px;
  line-height: 60px;
  > a {
    display: block;
  }
  @include xs {
    line-height: normal;
    display: flex;
    align-items: center;
  }
  @include sm {
    line-height: 40px;
  }
  @include md {
    width: 136px;
  }

  @include max-md {
    flex: 1 0 0;
    order: 1;
    margin-left: 35px;
    max-width: 130px;
    padding: 0 15px;
  }

  img {
    width: 100%;
    max-height: 55px;
    height: 100%;
    @include ie() {
      min-height: 35px;
    }
    @include xs {
      max-height: 28px;
    }
  }
}
.disabled {
  pointer-events: none;
  cursor: default;
}
