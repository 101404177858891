@import 'assets/scss/mixins/breakpoints.scss';

.menuIcon {
  position: absolute;
  z-index: 999;
  left: -5px;
  display: none;

  .icon {
    font-size: 16px;
    background: $white;
    border: 0;
    color: $light-gray;
    padding: 0;
  }

  @include sm {
    top: -94px;
    display: block;
  }
}

.hamburgerNavbar {
  margin-bottom: 18px;

  > ul {
    > li {
      > a {
        font-size: $xlg;
        line-height: 24px;
        font-family: $font-family-regular;
        color: $dark-gray;
        text-transform: uppercase;
      }
    }
  }

  > div {
    > button {
      flex-direction: row;
      padding-left: 0;

      span {
        margin: 0;
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }
}

.menuIconWithPwgs {
  top: -173px;

  @include min-xs {
    top: -94px;
  }
}
