@import 'assets/scss/mixins/breakpoints.scss';
ul.accountMenuList {
  max-width: 46%;
  flex: 0 0 46%;
  background: $hover-bg;
  min-height: 160px;

  > li {
    border-bottom: 1px solid $panel-border;
    > a {
      // padding: 5px 8px;
      font-weight: bold;

      &:hover {
        @include xs {
        }
      }
    }
  }
}
