@import 'assets/scss/mixins/breakpoints.scss';

.searchFormStyling {
  width: 100%;
  display: flex;

  @include xs {
    flex-wrap: wrap;
  }

  .visibleXs {
    display: none;

    @include xs {
      display: block;
    }
  }
}

.inputWrapper {
  &.selectWrapper {
    display: flex;
    align-items: center;

    @include lg {
      padding-right: 5px;
    }

    @include xs {
      margin-bottom: 5px;
      width: 100%;
    }

    height: 36px;
    padding-right: 10px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 0;
    border-right: 1px solid $light-gray;
    outline: none;
    background: $hover-bg;
    padding-left: 5px;

    @include xs {
      border-radius: 3px;
      border: 1px solid $light-gray;
    }

    .searchRadioButton {
      &:focus {
        outline: 2px solid $dark-gray;
        ~ label {
          box-shadow: 0 0 0 1px $dark-gray inset;
        }
      }
      ~ label {
        padding-right: 25px;
        padding-left: 0;

        @include min-md {
          font-size: $xs;
        }

        @include min-lg {
          font-size: $md;
        }

        &:before {
          left: auto;
          right: 0;
          top: 50%;
          bottom: 50%;
          margin: auto;
        }

        &:after {
          right: 3px;
          left: auto;
          top: 50%;
          bottom: 50%;
          margin: auto;
        }
      }
    }
  }

  &.searchWrapper {
    flex-grow: 1;
    position: relative;

    min-width: 200px;

    input {
      transition: all 0.2s ease-in-out;
      border: 0;
      border-radius: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      &:focus {
        box-shadow: $dark-gray 0px 0px 0px 1px inset;
      }

      @include xs {
        border: 1px solid $light-gray;
        padding-right: 42px;
      }
    }

    button {
      @include xs {
        margin-left: -40px;
      }
    }
  }

  &.searchWrapperXs {
    flex: 1;
  }
}
