@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.editDeleteButton {
  border: 1px solid $border-color;
  padding: 5px 10px;
  background: #f2f2f2;
  border-radius: 5px;
  z-index: 999;
  & > button {
    padding: 5px 0;
    font-size: 9px;
    cursor: pointer;
    display: block;
    text-align: left;
    @include min-sm {
      font-size: 13px;
    }
  }
  .assignButton {
    border-top: 1px solid $border-color;
    border-radius: 0;
  }
  .deleteButton {
    border-top: 1px solid $border-color;
    border-radius: 0;
    width: 100%;
    text-align: left;
  }
}
.editDeleteButton:hover {
  box-shadow: 0 0 11px rgb(33 33 33 / 20%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.tooltipStyle {
  padding: 0 !important;
  margin: 0 !important;
}
