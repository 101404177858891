@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/breakpoints.scss';

.size {
  font-size: $lg;
}

.defaultAddress {
  @include min-xs {
    padding-left: 5px;
  }
}
