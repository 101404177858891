@import 'assets/scss/mixins/breakpoints.scss';

.contextMenu {
  position: relative;
  > button {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    font-family: 'MotivasanRegular';
    color: $dark-gray;
    &:focus {
      border-radius: 0;
    }
    > span {
      padding: 0 0 0 10px;
      @include xs {
        padding-left: 5px;
      }
    }
  }
  .contextMenuList {
    position: absolute;
    min-width: 170px;
    top: 100%;
    left: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    margin: 25px 0 0 0;
    z-index: 9;
    box-shadow: $dropdown-box-shadow;
    background-color: $white;
    li {
      button {
        padding: 7px 12px;
        font-family: 'MotivasanRegular';
        border-radius: 0;
        width: 100%;

        &:hover,
        &:focus {
          background: $hover-bg;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
  }
  &:hover,
  &.active {
    .contextMenuList {
      opacity: 1;
      visibility: visible;
      margin: 10px 0 0 0;
    }
  }
}
