@import 'assets/scss/mixins/breakpoints.scss';
.skeletonNavbar svg {
  height: 45px;
  display: block;
  margin: auto;

  @include sm {
    display: none;
  }

  @include xs {
    display: none;
  }
}
