@import 'assets/scss/mixins/breakpoints.scss';

.filterButton {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  button {
    min-width: 100px;
    padding: 8px 10px;
    border-color: $input-border;
    color: $dark-gray;
    font-family: 'MotivasanRegular';
    margin-left: -1px;
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }
    &:last-child {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
    &.selected,
    &:hover,
    &:focus {
      background: $hover-bg;
    }
    @include xs {
      flex-grow: 1;
    }
  }
}
