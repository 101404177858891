@import 'assets/scss/mixins/breakpoints.scss';

.dialogContentWrapper {
  overflow-y: auto;
  padding: 25px 15px 25px 15px;
  text-align: center;
  .dialogHeader {
    margin-bottom: 20px;
  }
  .dialogContant {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}
.dialogHeaderBg {
  height: 30px;
  background-color: $border-color;
}
