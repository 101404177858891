@import 'assets/scss/mixins/breakpoints.scss';

.active {
  div {
    opacity: 1;
    visibility: visible;
    margin: 0;
    z-index: 999;
  }

  section {
    opacity: 1;
    visibility: visible;
    margin: 0;
  }
}

.supportMenuItem {
  position: absolute;
}
