@import 'assets/scss/mixins/breakpoints.scss';
.dialogHeader {
  background: $hover-bg;
  padding: 12px 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-family: $font-family-medium;
  }
  button {
    font-family: 'MotivasanRegular';
    font-size: $medium-font;
  }
}
.headingType {
  font-size: $fsXl;
  font-family: $font-family-medium;
}

.dialogContent {
  padding: 30px 6px;
  display: flex;
  flex-direction: column;
  text-align: center;
  > span {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.dialogFooter {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  & > button {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}
