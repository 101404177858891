@import 'assets/scss/variables.scss';

.mainBlock {
  padding: 12px 15px;

  input {
    border: 1px solid $input-border;
  }
  .content {
    padding: 15px 0;
  }
  .padding {
    padding: 9px 0;
  }
}
.borderBottom {
  border-bottom: 1px solid $panel-border;
}
